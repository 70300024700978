<template>
  <Layout  style="background-color: #e9ecef;max-height: 1000px">
    <PageHeader :title="title" />
    <hr style="margin-top: -1rem;height: 1px;"/>
    <div style="margin-top: -1rem;">
      <div class="row" style="height: auto">
        <div class="col-12">
          <form class="form-horizontal" role="form" @submit.prevent="blogForm" @submit="onblogFormSubmit" @reset="onblogFormReset">
            <b-form-group
                id="example text"
                label-for="text" >
              <b-form-input for="text" placeholder="请输入文章标题" style="width: 90%;float: left"
                            v-model="form.blogTitle"
                            :class="{ 'is-invalid': submitform && $v.form.blogTitle.$error }"></b-form-input>
              <div v-if="submitform && $v.form.blogTitle.$error" class="invalid-feedback">
                <span v-if="!$v.form.blogTitle.required">请输入文章标题</span>
              </div>
            </b-form-group>
          </form>
          <b-button type="primary" v-b-modal.modal-scrollable class="el-button--danger">发布文章</b-button>
        </div>
      </div>
      <div style="padding-top: 10px;" >
        <textarea name="editor1" v-model="form.blogContent"  id="editor1" type="inline" rows="500" cols="80"></textarea>
      </div>
    </div>
    <!--新建博客弹窗-->
    <div class="col-sm-12 col-md-4 col-xl-10">
      <div class="my-12 text-center">
        <b-modal
            size="lg"
            id="modal-scrollable"
            @shown="openModal"
            scrollable no-close-on-backdrop
            title="发布文章"
            title-class="font-18" hide-footer @close="resetForm">
          <div class="col-12 ">
            <form class="form-horizontal" role="form" @submit.prevent="blogForm" @submit="onblogFormSubmit" @reset="onblogFormReset">
              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章分类" class="form_group">
                <select @change="resetImageSearch" class="form-control" style="float: left;width:70%"  v-model="form.typeId" :class="{ 'is-invalid': submitform && $v.form.typeId.$error }">
                  <option  v-for="type in typeList" :key="type.id" :value="type.id">{{type.typeName}}</option>
                </select>
                <div class="icon-demo-content">
                  <div class="col-lg-4" style="margin-top: 0px;cursor:pointer"><i v-b-modal.modal-catlog class="ri-add-line"></i></div>
                </div>
                <div v-if="submitform && $v.form.typeId.$error" class="invalid-feedback">
                  <span v-if="!$v.form.typeId.required">请选择分类</span>
                </div>
              </b-form-group>

              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章标签"  class="form_group">

                <div class="radio" style="min-height: 150px;width:100%;border:1px #eeeeee solid;max-height: 200px;overflow: auto" :class="{ 'is-invalid invalid-div': submitform && $v.form.tagIds.$error }">
                  <div style="float: left;margin-top: 5px">
                    <label class="radio-inline" style="padding: 10px 10px"
                           v-for="tag in tagList" :key="tag.id" >
                      <input type="checkbox" name="optradio" :value="tag.id" v-model="form.tagIds" :checked="form.tagIds.includes(tag.id)"> {{tag.tagName}}
                    </label>
                  </div>


                  <div class="icon-demo-content">
                    <div class="col-lg-4" style="margin-top: 5px;cursor:pointer"><i v-b-modal.modal-tag class="ri-add-line" ></i></div>
                  </div>
                </div>
                <div v-if="submitform && $v.form.tagIds.$error" class="invalid-feedback">
                  <span v-if="!$v.form.tagIds.required">请选择标签</span>
                </div>
              </b-form-group>
              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章类型" class="form_group">
                <select class="form-control" v-model="form.blogType" :class="{ 'is-invalid': submitform && $v.form.blogType.$error }">
                  <option selected="selected" value="1">原创</option>
                  <option value="2">转载</option>
                  <option value="3">翻译</option>
                </select>
                <div v-if="submitform && $v.form.blogType.$error" class="invalid-feedback">
                  <span v-if="!$v.form.blogType.required">请选择文章类型</span>
                </div>
              </b-form-group>

              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="文章封面"
                  class="form_group"
              >
                <vue-dropzone
                    id="myVueDropzone"
                    ref="myVueDropzone"
                    :use-custom-slot="true"
                    :options="dropzoneOptions"
                    data-dz-remove
                    @vdropzone-drop="onDrop"
                    v-model="form.titlePage"
                >
                  <div class="dropzone-custom-content">
                    <p>拖拽文件到此处上传</p>
                  </div>
                </vue-dropzone>



                <div v-if="submitform && $v.form.titlePage.$error" class="invalid-feedback">
                  <span v-if="!$v.form.titlePage.required">请上传文章封面</span>
                </div>
              </b-form-group>
              <b-form-group
                  label-for="text"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="在线图片"
                  class="form_group"
              >
                <div class="image-preview-container">
                  <!-- 左侧的 Vue-Dropzone -->
                  <!-- 右侧图片预览 -->
                  <div class="image-preview"   v-for="(img, index) in this.images" :key="img.id"  :style="{ 'margin-left': index === 0 ? '0px' : '20px' }">
                    <img title="拖拽图片进行上传" :src="img.middleURL"  style="cursor: pointer;">
                  </div>
                  <div >
                    <b-button  variant="primary" @click="refreshImage">换一批</b-button>
                  </div>
                </div>
              </b-form-group>

              <div style="margin-top: 20px;float: right;padding: 20px 20px">
                <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                <b-button  variant="primary" type="submit">保存</b-button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
    </div>
    <!--新建分类弹窗-->
    <div class="col-sm-12 col-md-4 col-xl-10">
      <div class="my-12 text-center">
        <b-modal
            size="lg"
            id="modal-catlog"
            scrollable
            title="新建分类"
            title-class="font-18" hide-footer>
          <div class="col-12">
            <form class="form-horizontal" role="catlogForm" @submit.prevent="catlogFormValid" @submit="onSubmit" @reset="onReset">
              <b-form-group
                  label-for="text"
                  label-cols-lg="2"
                  label="分类名称" >
                <input
                    id="validationTooltip03"
                    v-model="catlogForm.typeName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': submitcatlogForm && $v.catlogForm.typeName.$error }"
                />
                <div v-if="submitcatlogForm && $v.catlogForm.typeName.$error" class="invalid-feedback">
                  <span v-if="!$v.catlogForm.typeName.required">请填写分类名称</span>
                </div>
              </b-form-group>
              <div style="margin-top: 20px;float: right;padding: 20px 20px">

                <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                <b-button  variant="primary" type="submit">保存</b-button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
    </div>
    <!--新建标签弹窗-->
    <div class="col-sm-12 col-md-4 col-xl-10">
      <div class="my-12 text-center">
        <b-modal
            size="lg"
            id="modal-tag"
            scrollable
            title="新建标签"
            title-class="font-18" hide-footer>
          <div class="col-12">
            <form class="form-horizontal" role="form" @submit.prevent="tagFormValid" @submit="onTagFormSubmit" @reset="onTagFormReset">
              <b-form-group
                  label-for="text"
                  label-cols-lg="2"
                  label="标签名称" >
                <input
                    id="validationTooltip"
                    v-model="tagForm.tagName"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': submitTagForm && $v.tagForm.tagName.$error }"
                />
                <div v-if="submitTagForm && $v.tagForm.tagName.$error" class="invalid-feedback">
                  <span v-if="!$v.tagForm.tagName.required">请填写标签名称</span>
                </div>
              </b-form-group>
              <div style="margin-top: 20px;float: right;padding: 20px 20px">
                <b-button class="btn btn-default" style="background-color: gray;color: white;margin-right: 10px" type="reset">取消</b-button>
                <b-button  variant="primary" type="submit">保存</b-button>
              </div>
            </form>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>
<script src="../..ckeditor/ckeditor.js">
import {select} from "jodit/types/plugins/select/select";
import {defineComponent} from "vue";

export default defineComponent({
  computed: {
    select() {
      return select
    }
  }
})

</script>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import vue2Dropzone from "vue2-dropzone";
import {required} from "vuelidate/lib/validators";
import axios from "axios";


/**
 * Calendar component
 */
var titlePage;
export default {
  name: "blog",
  page: {
    title: "发布文章"
  },
  components: {vueDropzone: vue2Dropzone,Layout, PageHeader },
  data() {
    return {
      title: "发布文章",

      curCode:'',
      codeInput:false,
      cmOptions:{
        value:'',
        mode:"text/javascript",
        theme: "ambiance",
        readOnly: false,
      },

      dropzoneOptions: {
        // url: "http://localhost:8099/myblog/upload",
        url: '/myblog/upload',
        thumbnailWidth: 150,
        maxFiles:1,//一次性上传的文件数量上限
        maxFilesize: 100, //MB
        acceptedFiles: ".jpg,.gif,.png,.jpeg,.webp", //上传的类型
        addRemoveLinks: true,
        dictRemoveFile:'移除图片',
        headers: { },
        init:function(){
          this.on("addedfile", function(file) {
            // eslint-disable-next-line no-debugger
            console.log(file)
            //上传文件时触发的事件
          });
          this.on("removedfile",function(file){
            console.log(file)
            titlePage = ''
            //删除文件时触发的方法
          });
          this.on("success", function(file,res) {
            titlePage = res.fileName;
            console.log("success",titlePage)
          });
          this.on("error", function(file, message, xhr){
            console.log("file,{},err msg:{}",file,message)
          });
        }
      },
      submitform: false,
      submitcatlogForm:false,
      submitTagForm:false,

      form: {
        blogTitle: '',
        blogContent: '',
        titlePage: '',
        blogType: '',
        summary: '',
        quoteUrl: '',
        publishFlag: '',
        typeId:'',
        tagIds: [],
      },
      catlogForm: {
        id:'',
        typeName:'',
      },
      tagForm: {
        id:'',
        tagName:'',
      },
      typeList:[],
      tagList:[],
      images:[],
      imagePageNo:1,
    }
  },
  methods: {
    async uploadFile(file) {
      try {
        const formData = new FormData();
        formData.append('file', file); // 'file'是你后台接收文件的字段名称

        const response = await this.uploadFormData(formData);
        console.log('文件上传成功:', response);
        return response;
      } catch (error) {
        console.error('文件上传失败:', error);
      }
    },
    async uploadFormData(formData) {
      const url = '/myblog/upload'; // 你的后端上传文件的URL

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(xhr.statusText);
          }
        };
        xhr.onerror = () => reject(xhr.statusText);
        xhr.send(formData);
      });
    },
    async onDrop(event) {
      event.preventDefault();
      const imageUrl = event.dataTransfer.getData('URL'); // 获取拖拽的图片地址
      // 执行上传逻辑，将图片地址或数据提交到后端
      // 使用 imageUrl 或根据需求获取其他拖拽的数据进行上传
      const file = await this.getBase64Img (imageUrl)
      console.log("====>",file)
      // 启动上传流程
        console.log("开始手动上传")
        const res = await this.uploadFile(file);
      const resObj = JSON.parse(res);
      console.log("获取res对象",res)
      console.log("获取resObj对象",resObj)
        this.$nextTick(()=>{
      console.log("获取图片url",resObj.url)
          titlePage = resObj.fileName;
          this.form.titlePage = titlePage;
          console.log(resObj.url);
          if (resObj && resObj.url) {
            this.$refs.myVueDropzone.manuallyAddFile(file, resObj.url);
            console.log("图片上传完毕。this.form",this.form,this.res)
          } else {
            console.error("URL is undefined in the response:", resObj);
          }
        })
    },
    async getBase64Img(params) {
        const {data: res} = await this.$blog.post("/upload/imageUrlToFile", {imageUrl: params});
        const base64Data = res; // 假设 res 是你从后端获取的 Base64 字符串
        console.log("base64Data:{}","data:image/png;base64,"+base64Data);
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: 'image/png'}); // 设置图片类型

        // 构建 File 对象
        const filename = 'image.png'; // 设置文件名
        const file = new File([blob], filename, {type: 'image/png'});
        console.log("get imgs file by rest api", file);
        return file;
    },

    async onblogFormSubmit(evt) {
      this.form.titlePage = titlePage,
          evt.preventDefault()
      let url = '/blog/insert';
      if (this.form.id != '' && this.form.id != undefined) {
        url = '/blog/update';
      }
      this.form.blogContent = CKEDITOR.instances.editor1.getData();
      // eslint-disable-next-line no-debugger
      if (this.$v.form.$invalid) {
        return;
      } else {
        const {data: res} = await this.$blog.post(url,this.form )
        if (res.code !== 200) {
          this.makeToast('danger',res.msg)
          return null
        } else  {
          if (this.$v.form.id != '' && this.$v.form.id != undefined) {
            window.history.go(-1);
          }
          this.resetForm();
          this.makeToast('success',res.msg)
          this.$bvModal.hide("modal-scrollable");
          this.submitform = false;
          return res.data
        }

      }
      this.resetImageSearch()
    },
    resetForm(){
      if (this.form.id == null || this.form.id == '') {
        this.form = {
          blogTitle: '',
          blogContent: '',
          titlePage: '',
          blogType: '',
          summary: '',
          quoteUrl: '',
          publishFlag: '',
          typeId:'',
          tagIds: [],
        };
        CKEDITOR.instances.editor1.setData("");
      }

      this.$bvModal.hide("modal-scrollable");
      this.submitform = false;
    },
    onblogFormReset(evt) {
      evt.preventDefault()
      this.$bvModal.hide("modal-scrollable");
      this.submitform = false;
    },

    blogForm() {
      this.submitform = true;
      this.$v.form.$touch();
    },
    catlogFormValid(){
      this.submitcatlogForm = true;
      this.$v.catlogForm.$touch();
    },
    async onSubmit(evt) {
      evt.preventDefault()
      if (this.$v.catlogForm.$invalid) {
        return;
      } else {
        let url = '/type/insert';
        if (this.catlogForm.id != '') {
          url = '/type/update';
        }
        const {data: res} = await this.$blog.post(url,this.catlogForm )
        if (res.code !== 200) {
          this.makeToast('danger',res.msg)
          return null
        } else  {
          this.resetCatlogForm();
          this.makeToast('success',res.msg)
          this.$bvModal.hide("modal-catlog");
          this.submitcatlogForm = false;
          this.getTypeList();
          return res.data
        }

      }

    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.resetCatlogForm();
      this.$bvModal.hide("modal-catlog");
      this.submitcatlogForm = false;
    },
    resetCatlogForm(){
      this.catlogForm = {
        typeName: '',
        id:'',
      }
    },

    tagFormValid(){
      this.submitTagForm = true;
      this.$v.$touch();
    },
    async onTagFormSubmit(evt) {
      evt.preventDefault()
      if (this.$v.tagForm.$invalid) {
        return;
      } else {
        let url = '/tag/insert';
        if (this.tagForm.id != '') {
          url = '/tag/update';
        }
        const {data: res} = await this.$blog.post(url,this.tagForm )
        if (res.code !== 200) {
          this.makeToast('danger',res.msg)
          return null
        } else  {
          this.resetTagForm();
          this.makeToast('success',res.msg)
          this.$bvModal.hide("modal-tag");
          this.submitTagForm = false;
          this.getTagList();
          return res.data
        }

      }
    },
    onTagFormReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.resetTagForm();
      this.$bvModal.hide("modal-tag");
      this.submitTagForm = false;
    },
    resetTagForm(){
      this.tagForm = {
        tagName: '',
        id:'',
      }
    },
    async getTypeList(){
      // 得到所有的分类
      const {data: res} = await this.$blog.get('/type/getall')
      this.typeList = res.data
    },
    async getTagList(){
      // 得到所有的分类
      const {data: res} = await this.$blog.get('/tag/getall')
      this.tagList = res.data
    },
    makeToast(variant = null,msg = null) {
      this.$bvToast.toast(msg, {
        title: variant,
        variant: variant,
        solid: true
      });
    },
    openModal(){
      this.getTagList();
      this.getTypeList();
      if (this.form.titlePage != null && this.form.titlePage != undefined && this.form.titlePage != '') {
        // eslint-disable-next-line no-debugger
        var file = {size:1,name: "Icon", type: "image/png" };
        var url = this.picServer + this.form.titlePage;
        titlePage = this.form.titlePage;
        console.log(this.form.titlePage)
        this.$nextTick(()=>{
          this.$refs.myVueDropzone.manuallyAddFile(file, url);
        })
      }
    },
    getTypeName() {
      // 获取当前选中的 type 对象
      const selectedType = this.typeList.find(type => type.id === this.form.typeId);
      if (selectedType) {
        const typeName = selectedType.typeName;
        // 这里可以对获取到的 typeName 进行操作
        return typeName;
      }
    },
    resetImageSearch(){
      this.imagePageNo = 1;
      this.images = [];
    },
    // 获取百度图片 https://image.baidu.com/search/acjson
    refreshImage(){
      // let url = 'https://image.baidu.com/search/acjson';
      let url = '/baidu-image-search';
      const queryParams = {
        tn: "resultjson_com",
        logid: 5974571204660353919,
        ipn: "rj",
        ct: 201326592,
        is: "",
        fp: "result",
        fr: "",
        word: this.getTypeName(),
        queryWord: this.getTypeName(),
        cl: 2,
        lm: -1,
        ie: "utf-8",
        oe: "utf-8",
        adpicid:"",
        st: 1,
        z: 9,
        ic: 0,
        hd: "",
        latest: "",
        copyright:"",
        s: "",
        se: "",
        tab: "",
        width: "",
        height: "",
        face: 0,
        istype: 2,
        qc: "",
        // nc: 1,
        expermode:"",
        nojc: "",
        isAsync: "",
        rn: 3,
        pn: this.imagePageNo++ * 3,
        gsm: "le",
        1702130855734: "",
      };

      // 发送 GET 请求，并将查询参数作为 `params` 传递
      axios.get(url, {
        params: queryParams
      })
          .then(response => {
            // 请求成功的处理
            this.images = response.data.data.slice(0, -1); // 使用 slice 方法获取除了最后一个元素之外的部分
            console.log('images:', this.images);
          })
          .catch(error => {
            // 请求失败的处理
            console.error('Error:', error);
          });
    }

  },
  validations: {
    form: {
      blogTitle: { required },
      titlePage: { required },
      blogType: { required },
      typeId: { required },
      tagIds: { required }
    },
    catlogForm: {
      typeName: { required },
    },
    tagForm: {
      tagName: { required },
    },

  },
  mounted() {
    document.getElementById("layout-header").hidden = true;
    document.getElementById("layout-footer").hidden = true;
    CKEDITOR.replace( 'editor1', {
      extraPlugins: 'codesnippet,uploadimage,image2',
      codeSnippet_theme: 'monokai_sublime'
    } );

  },
  created() {
    this.getTypeList();
    this.getTagList();
    if (typeof this.$route.query.blog !== 'undefined'){
      this.form = JSON.parse(this.$route.query.blog)
    }
    let tagList = this.form.tagList;
    let tagIds = [];
    if (tagList!= null && tagList != undefined) {
      tagList.map(tag=>{
        tagIds.push(tag.id);
      })
    }
    this.form.tagIds = tagIds;
    console.log(this.form);
  },

};
</script>


<style>
.el-button--danger {
  float: left;
  color: #FFF;
  background-color: #F56C6C;
  border-color: #F56C6C;
  border-radius: 10%;
  margin-left: 20px;;
}
.invalid-div{
  border: 1px solid red !important;
}
.icon-demo-content {
  color: #adb5bd;
  float: left;
}

/* 左右布局的容器样式 */
.image-preview-container {
  display: flex;
}

/* 左侧 Vue-Dropzone 组件的样式 */
.image-preview {
  width: 150px; /* 设置预览图片框的宽度 */
  height: 100px; /* 设置预览图片框的高度 */
  border: 1px dashed  #ccc; /* 添加边框 */
  margin-left: 20px; /* 可根据需要调整左边距 */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 预览图片样式 */
.image-preview img {
  max-width: 100%; /* 图片最大宽度 */
  max-height: 100%; /* 图片最大高度 */
}

#myVueDropzone {
  width: 200px;
  height: 200px;
}
</style>
